<div class="skills-container">
    <span>{{ title }}</span>
    <div class="skills-list">
        <div class="skill-item" *ngFor="let skill of skills">
            <img [src]="skill.icon">
            <p>{{ skill.name }}</p>
        </div>
    </div>
    <div class="learn">
        <img src="assets/img/learn.svg" alt="Learn more" class="learn-image">
        <div class="info-window">
            {{ specialInterest }}
            <div class="interests">
                <div class="interest">
                    <img src="assets/img/python.png">
                    <p>Python</p>
                </div>
                <div class="interest">
                    <img src="assets/img/django.png">
                    <p>Django</p>
                </div>
                <div class="interest">
                    <img src="assets/img/cloud.png">
                    <p>Cloud</p>
                </div>
            </div>
        </div>
        <p>{{ letMeKnow }} <a href="#contact">{{ letMeKnowLink }}</a> — {{ letMeKnowDescription }}</p>
    </div>
</div>
<div class="arrowleft">
    <img src="assets/img/arrowleft.png">
</div>
