<div class="color"></div>
<div class="contact">
    <div class="content">
        <div class="headlines">    
            <div>
                <h1>{{ sayHi }}</h1> <!-- Dynamisch übersetzter Text -->
            </div>
            <div>
                <h2>{{ discussProject }}</h2> <!-- Dynamisch übersetzter Text -->
                <h3>{{ letsDiscuss }}</h3> <!-- Dynamisch übersetzter Text -->
            </div>
        </div>

        <form (ngSubmit)="onSubmit(contactForm)" #contactForm="ngForm">
            <div>
                <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="{{ placeholderName }}" 
                    [(ngModel)]="contactData.name"
                    required
                    #name="ngModel"
                    [ngClass]="{'invalid': name.invalid && (name.dirty || name.touched)}"
                />
                <div *ngIf="name.invalid && (name.dirty || name.touched)" class="error">
                    {{ nameRequiredError }}
                </div>
            </div>
        
            <div>
                <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="{{ placeholderEmail }}"
                    [(ngModel)]="contactData.email"
                    required
                    pattern="[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}"
                    #email="ngModel"
                    [ngClass]="{'invalid': email.invalid && (email.dirty || email.touched)}"
                />
                <div *ngIf="email.invalid && (email.dirty || email.touched)" class="error">
                    <span *ngIf="email.errors?.['required']">{{ emailRequiredError }}</span>
                    <span *ngIf="email.errors?.['pattern']">{{ emailInvalidError }}</span>
                </div>
            </div>
        
            <div>
                <textarea
                    id="message"
                    name="message"
                    placeholder="{{ placeholderMessage }}"
                    [(ngModel)]="contactData.message"
                    rows="5"
                    required
                    #message="ngModel"
                    [ngClass]="{'invalid': message.invalid && (message.dirty || message.touched)}"
                ></textarea>
                <div *ngIf="message.invalid && (message.dirty || message.touched)" class="error">
                    {{ messageRequiredError }}
                </div>
            </div>
        
            <div>
                <label for="subscribe">
                    <input
                        class="checkbox"
                        type="checkbox"
                        id="subscribe"
                        name="subscribe"
                        ngModel
                        required
                        #subscribe="ngModel"
                    />
                    <span>
                        {{ privacyTextBeforeLink }}
                        <a href="imprint">{{ privacyLinkText }}</a>
                        {{ privacyTextAfterLink }}
                    </span>
                </label>
                <div *ngIf="subscribe.invalid && (subscribe.dirty || subscribe.touched)" class="error">
                    {{ privacyPolicyError }}
                </div>
            </div>
        
            <button
                type="submit"
                [disabled]="!contactForm.form.valid || !subscribe.value"
                class="submit-btn">
                <span class="desktop-text">{{ sendMessageText }}</span> <!-- Dynamischer Text -->
                <span class="mobile-text">{{ mobileText }}</span> <!-- Dynamischer Text -->
            </button>
        </form>
        <div>
            <a class="scrollup" href="#">
                <img src="assets/img/scrollup.png">
            </a>
        </div>
    </div>
</div>

<!-- Benachrichtigungs-Element, das nach erfolgreichem Versand angezeigt wird -->
<div class="notification" *ngIf="showNotification">
  {{ successMessage }}
</div>
