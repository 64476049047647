<div class="about-me">
  <div class="headline-container">
    <span class="headline">{{ headline }}</span>
  </div>
  <div class="text-img">
    <div class="text">
      <span [innerHTML]="text"></span>
      <div class="house">
        <img src="assets/img/house.png">
        <span>{{ basedIn }}</span>
      </div>
      <div class="remote">
        <img src="assets/img/laptop.png">
        <span>{{ remoteWork }}</span>
      </div>
      <div class="button-container">
        <a class="button" href="#contact">{{ button }}</a>
      </div>
    </div>
    <div class="img">
        <img src="assets/img/4.jpg">
        <div class="box">
          <div class="box-border"></div>
        </div>
    </div>
  </div>
  <div class="arrowleft">
    <img src="assets/img/arrowleft.png">
  </div>
</div>
