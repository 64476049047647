<div class="projects">
  <div class="headline-container">
    <span>Portfolio</span>
    <p>{{ headlineDescription }}</p> <!-- Dynamisch übersetzter Text -->
  </div>

  <div class="content">
    <div
      class="project-card  {{ i % 2 == 1 ? 'reverse' : '' }}" [attr.data-index]="i"
      *ngFor="let project of projects; let i = index"
      #projectCard>
      <div class="project-image">
        <img [src]="project.img" [alt]="project.title">
        <div class="box">
          <div class="box-border"></div>
          <div class="box-arrow"></div>
        </div>
      </div>

      <div class="project-details">
        <h3>{{ project.title }}</h3>
        <span>{{ project.languages }}</span>
        <p>{{ project.description }}</p> <!-- Dynamisch übersetzte Beschreibung -->
        <div class="btn-container">
          <a href="{{ project.githuburl }}" target="_blank"><button>GitHub</button></a>
          <a href="{{ project.projecturl }}" target="_blank"><button>Test</button></a>
        </div>
      </div>
    </div>
  </div>
</div>
