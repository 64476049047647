<div class="landing-page">
    <h1>{{ title }}</h1>  <!-- Titel wird dynamisch durch Übersetzungen ersetzt -->
    <div class="container">    
        <hr class="horizontal-line">
        <span class="name">Onur Copur</span>  <!-- Name dynamisch übersetzen -->
    </div>
    <div class="mail-scroll">
        <div class="mail">copuronur&#64;outlook.de</div>
        <div class="scroll">
            <img src="assets/img/scroll_arrow.png" alt="">
            <p [innerHTML]="scrollText"></p>  <!-- Scroll-Text dynamisch -->
        </div>
    </div>
</div>
