<div class="footer">
    <div class="logo-links">
        <div class="logo-name">
            <a href="">
                <img src="assets/img/logofooter.png">
            </a>
            <span>© Onur Copur 2024</span>
        </div>
        
        <div class="links-ln">
            <div class="links">
                <a href="https://github.com/OnurCopur" target="_blank">
                    <img src="assets/img/githublogo.png" alt="GitHub">
                </a>
                <a href="mailto:copuronur@outlook.de" target="_blank">
                    <img src="assets/img/maillogo.png" alt="Email">
                </a>
                <a href="https://linkedin.com/" target="_blank">
                    <img src="assets/img/linkedinlogo.png" alt="LinkedIn">
                </a>
                
            </div>
            <a class="imprint" href="imprint"><span>Legal notice</span></a>
        </div>
    </div>
   
</div>